// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chameleon .ft-switch .switch-icon {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./chameleon/legacy/packages/ft-cb/src/components/Switch/index.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ","sourcesContent":[".ft-switch {\n  .switch-icon {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
