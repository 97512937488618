import React from 'react';
import PoweredBy from './PoweredBy';

const PageFooter = () => (
  <footer>
    <PoweredBy />
  </footer>
);

export default PageFooter;
