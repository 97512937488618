// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chameleon .ScrollableContainer .horizontal-track,
.chameleon .ScrollableContainer .vertical-track {
  border-radius: 2px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.chameleon .ScrollableContainer .horizontal-track {
  left: 0;
  bottom: 0;
  width: 100%;
}
.chameleon .ScrollableContainer .vertical-track {
  top: 0;
  right: 0;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./chameleon/legacy/packages/ft-cb/src/components/ScrollableContainer/styles.scss"],"names":[],"mappings":"AACE;;EAEE,kBAAA;EACA,kBAAA;EAAA,yDAAA;AAAJ;AAGE;EACE,OAAA;EACA,SAAA;EACA,WAAA;AADJ;AAIE;EACE,MAAA;EACA,QAAA;EACA,YAAA;AAFJ","sourcesContent":[".ScrollableContainer {\n  .horizontal-track,\n  .vertical-track {\n    border-radius: 2px;\n    @apply bg-white;\n  }\n\n  .horizontal-track {\n    left: 0;\n    bottom: 0;\n    width: 100%;\n  }\n\n  .vertical-track {\n    top: 0;\n    right: 0;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
