// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `#0076c4`,
	"secondary": `#1e1e1e`,
	"error": `#e75b6b`,
	"tabsEdge": `#ececec`,
	"dark": `#1e1e1e`,
	"border": `1px solid #ececec`,
	"blue": `#0076c4`,
	"white": `#fff`,
	"gray": `#999896`,
	"focusColor": `rgba(0, 156, 222, 0.8)`,
	"focusRadius": `3px`,
	"padding": `8px 16px`,
	"fontWeight": `500`,
	"fontWeightSelected": `400`,
	"boxShadowSelected": `inset 0 0 0 1px #ececec`,
	"minHeight": `49px`,
	"scrollButtonsWidth": `32px`,
	"marginBottom": `20px`,
	"tabsBorderBottom": `3px solid transparent`,
	"tabSelectedBorderBottom": `1px solid #ffffff`,
	"tabWidthLg": `200px`,
	"tabWidthMd": `150px`,
	"lineHeight": `1.44`,
	"colorFade": `rgba(255, 255, 255, 0.8)`
};
export default ___CSS_LOADER_EXPORT___;
