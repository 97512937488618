// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chameleon .powered_by {
  display: inline-block;
  width: 11rem;
  height: 3.6rem;
  color: #4d6c94;
}

.chameleon .powered_by--styled {
  position: fixed;
  bottom: 0;
  padding: 0.5rem;
  border-width: 0.1rem 0.1rem 0 0;
  border-style: solid;
  border-radius: 0 0.3rem 0 0;
  border-color: #ccc;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./chameleon/legacy/packages/ft-cb/src/components/PageFooter/PoweredBy/index.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,YAAA;EACA,cAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;EACA,SAAA;EACA,eAAA;EACA,+BAAA;EACA,mBAAA;EACA,2BAAA;EACA,kBAAA;EACA,kBAAA;EAAA,yDAAA;EACA,UAAA;AACF","sourcesContent":[".powered_by {\n  display: inline-block;\n  width: 11rem;\n  height: 3.6rem;\n  color: #4d6c94;\n}\n\n.powered_by--styled {\n  position: fixed;\n  bottom: 0;\n  padding: 0.5rem;\n  border-width: 0.1rem 0.1rem 0 0;\n  border-style: solid;\n  border-radius: 0 0.3rem 0 0;\n  border-color: #ccc;\n  @apply bg-white;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
