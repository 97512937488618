import React from 'react';

import './index.scss';

const BubbleSpinner = () => (
  <div className="sk-three-bounce">
    <div className="sk-child sk-bounce1" />
    <div className="sk-child sk-bounce2" />
    <div className="sk-child sk-bounce3" />
  </div>
);

export default BubbleSpinner;
