import React, { lazy, Suspense, useEffect, useReducer, useRef } from 'react';
import get from 'lodash/get';
import SplitIOHandler from 'chameleon/ui-stack/utilities/split-io-handler';
import Context, { TYPES, reducer, initialValue } from './state/Context';
import { LoadingSpinner } from 'legacy-ft-cb';

const LegacyApp = lazy(() => import('client-src/index'));
const ChameleonApp = lazy(() => import('chameleon-src/Root'));

const CHAMELEON_FFS = {
  global: 'chameleon_app',
  ap: 'ap_chameleon_app',
  preap: 'preap_chameleon_app',
  preap_chameleon_app_2025: 'preap_chameleon_app_2025',
};
const fetchFlags = (keys, uid, callback, listenCallback) => {
  const flagHandler = new SplitIOHandler({
    key: uid || '1',
    authorizationKey: SPLITIO_API_KEY,
    splitName: keys,
    listenCallback: (treatment) => {
      if (treatment) {
        listenCallback(treatment);
      } else {
        throw new Error('P2 Flag error');
      }
    },
    callback: (treatment) => {
      if (treatment) {
        callback(treatment);
      } else {
        throw new Error('P2 Flag error');
      }
    },
  });
  flagHandler.invoke();
};

const AppLoader = () => {
  const reducerProvider = useReducer(reducer, initialValue);
  const [state, dispatch] = reducerProvider;
  const appTreatmentRef = useRef(null);

  const isFfChameleonEnabled = (treatments) =>
    treatments[CHAMELEON_FFS.global] === 'on' ||
    treatments[CHAMELEON_FFS.ap] === 'on' ||
    treatments[CHAMELEON_FFS.preap] === 'on' ||
    treatments[CHAMELEON_FFS.preap_chameleon_app_2025] === 'on';

  const loadApp = () => {
    const chameleonAppLC = window.sessionStorage.getItem('USE_CHAMELEON_APP');
    const canLoadApp =
      chameleonAppLC !== null ? chameleonAppLC === 'true' : true;
    const useChameleonApp = appTreatmentRef.current && canLoadApp;

    dispatch({
      type: TYPES.SET_USE_CHAMELEON,
      payload: {
        useChameleonApp,
      },
    });
  };

  useEffect(() => {
    const callback = (treatments) => {
      appTreatmentRef.current = isFfChameleonEnabled(treatments);
      loadApp();
    };
    fetchFlags(
      [CHAMELEON_FFS.global, CHAMELEON_FFS.ap, CHAMELEON_FFS.preap],
      get(window, 'current_user.initId', -1),
      callback,
      callback,
    );
  }, []);

  const { useChameleonApp } = state;

  useEffect(() => {
    const html = document.querySelector('html');
    const body = document.querySelector('body');
    if (useChameleonApp) {
      html.classList.add('chameleon', 'chameleon-html');
      body.classList.add('chameleon-body');
      html.classList.remove('legacy');
    } else {
      html.classList.remove('chameleon', 'chameleon-html');
      body.classList.remove('chameleon-body');
      html.classList.add('legacy');
    }
  }, [useChameleonApp]);

  if (useChameleonApp === null) {
    return null;
  }
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Context.Provider value={reducerProvider}>
        {useChameleonApp ? (
          <ChameleonApp key="chameleon" />
        ) : (
          <LegacyApp key="legacy" />
        )}
      </Context.Provider>
    </Suspense>
  );
};

export default AppLoader;
