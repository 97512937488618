// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chameleon .sk-three-bounce {
  margin: 40px auto;
  width: 80px;
  text-align: center;
}
.chameleon .sk-three-bounce .sk-child {
  width: 20px;
  height: 20px;
  background-color: #0077c8;
  border-radius: 100%;
  display: inline-block;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}
.chameleon .sk-three-bounce .sk-bounce1 {
  animation-delay: -0.32s;
}
.chameleon .sk-three-bounce .sk-bounce2 {
  animation-delay: -0.16s;
}
@keyframes sk-three-bounce {
  0%, 80%, 100% {
    transform: scale(0);
    filter: alpha(opacity=0);
    opacity: 0;
  }
  40% {
    transform: scale(1);
    filter: alpha(opacity=1);
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./chameleon/legacy/packages/ft-cb/src/components/BubbleSpinner/index.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,WAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,qBAAA;EAEA,4DAAA;AACJ;AAEE;EAEE,uBAAA;AAAJ;AAGE;EAEE,uBAAA;AADJ;AAkBA;EACE;IAIE,mBAAA;IACA,wBAAA;IACA,UAAA;EAPF;EASA;IAEE,mBAAA;IACA,wBAAA;IACA,UAAA;EAPF;AACF","sourcesContent":[".sk-three-bounce {\n  margin: 40px auto;\n  width: 80px;\n  text-align: center;\n\n  .sk-child {\n    width: 20px;\n    height: 20px;\n    background-color: #0077c8;\n    border-radius: 100%;\n    display: inline-block;\n    -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;\n    animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;\n  }\n\n  .sk-bounce1 {\n    -webkit-animation-delay: -0.32s;\n    animation-delay: -0.32s;\n  }\n\n  .sk-bounce2 {\n    -webkit-animation-delay: -0.16s;\n    animation-delay: -0.16s;\n  }\n}\n\n@-webkit-keyframes sk-three-bounce {\n  0%,\n  80%,\n  100% {\n    -webkit-transform: scale(0);\n    transform: scale(0);\n  }\n  40% {\n    -webkit-transform: scale(1);\n    transform: scale(1);\n  }\n}\n\n@keyframes sk-three-bounce {\n  0%,\n  80%,\n  100% {\n    -webkit-transform: scale(0);\n    transform: scale(0);\n    filter: alpha(opacity=0);\n    opacity: 0;\n  }\n  40% {\n    -webkit-transform: scale(1);\n    transform: scale(1);\n    filter: alpha(opacity=1);\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
