import axios from 'axios';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import { getSignedUrl } from 'legacy/src/shared/clients/apiClient';
import { getFinishedPercent } from 'legacy/src/components/learnosity/utils/helpers';
import { getScoredSubmissions } from 'chameleon-src/components/learnosity/utils/helpers';
import { LC_CONSTANTS } from 'legacy/src/components/impersonating/utils/constants';

import { redirectToLogin } from 'legacy/src/components/auth/utils';

/**
 * From https://stackoverflow.com/a/41716722 and https://stackoverflow.com/a/48764436
 * @param {*} num
 * @param {*} decimalPlaces
 */
export const round = (num, decimalPlaces = 0) => {
  const p = 10 ** decimalPlaces;
  const m = num * p * (1 + Number.EPSILON);
  return Math.round(m) / p;
};

/*
 * Checks a string for a valid email syntax.
 * @return {boolean}
 */
export function validateEmail(address) {
  // eslint-disable-next-line max-len
  const regexp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!address) {
    return false;
  }

  return regexp.test(address);
}

export function getParameterByName(name, urlToParse) {
  let url = urlToParse;
  if (!url) url = window.location.href;
  const regexName = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${regexName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * Determines whether the user needs to be redirected to a different portal (if not a pilot account).
 * @todo Refactor this to use a regexp test.
 */
export function redirectRequiredCheck(redirectUrl) {
  const getLocation = (url) => {
    let l = document.createElement('a');

    l.href = url;

    return l;
  };

  return window.location.hostname !== getLocation(redirectUrl).hostname;
}

/**
 * Common autocomplete/fill props for input elements.
 */
export const sensitiveFieldProps = {
  autoComplete: 'off',
  autoCorrect: 'off',
  spellCheck: 'off',
};

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVYXYZ';

export const createMCQAnswersTable = (values) => {
  const answerLabels = {};
  values.forEach(
    (value, index) => (answerLabels[value] = ALPHABET.charAt(index)),
  );
  return answerLabels;
};

/*
 * Stores in localstorage the userType
 * @param {object} roles utility/User.js/userRoles object
 * */
export const storeUserType = (me) => {
  let userType;
  /**
   * Roles resolution order staff -> coordinator -> admin -> teacher -> student
   */
  if (me.isStaff()) {
    userType = 'staff';
  } else if (me.isCoordinator()) {
    userType = 'coordinator';
  } else if (me.isAdmin()) {
    userType = 'admin';
  } else if (me.isStudent()) {
    userType = 'student';
  } else if (me.isTeacher()) {
    userType = 'teacher';
  }

  if (!userType) {
    redirectToLogin();
  }

  window.localStorage.setItem('userType', userType);
};

/**
 * Simple string trim utility. Uses String.prototype.trim, with type checking.
 * @param {string} str String to trim.
 * @return {string}
 */
export const trim = (str) => {
  /**
   * String.prototype.trim throws an error when argument is not a string.
   */
  if (str && typeof str === 'string') {
    return str.trim();
  }

  return '';
};

/**
 * Gets the filter object from localStorage
 * */
export const getSavedGroupsFilter = () => {
  const selectedGroups = window.localStorage.getItem('groupsFilterQuery');

  if (selectedGroups !== null) {
    return JSON.parse(selectedGroups);
  }

  return { school: [], class: [] };
};

/**
 * Create classes handler
 * @param {string} subject The subject id
 * @param {object} router The react-router handler object
 * */
export const createClassRedirect = (subject, router) => {
  if (FEATUREFLAGS.myClasses.redirectRos) {
    window.location.href = `${ROS_FRONTEND_URL}/courses?create=${subject}`;
  } else {
    router.push(`/${subject}/classes`);
  }
};

export function redirectToCBLegacyWithTokens(nextURL) {
  const _nextURL = encodeURIComponent(nextURL);
  const accountAccessToken = window.localStorage.getItem(
    'account_access_token',
  );
  const accountRefreshToken = window.localStorage.getItem(
    'account_refresh_token',
  );
  const expires = window.localStorage.getItem('expires');
  const finalURL = `${CB_LEGACY_URL}/n/auth/redirect/?next=${_nextURL}&jwt=${accountAccessToken}&refresh=${accountRefreshToken}&expires=${expires}`;
  window.location.href = finalURL;
}

/**
 * Convert bucket URL to signed URL
 * @param {string} url The original URL to be signed.
 * @param {string} bucket The S3 bucket corresponding to the URL.
 * @returns {url}
 */
async function getSignedResource(url, bucket, failIfKeyIsMissing) {
  const vhost = `${bucket}.s3.amazonaws.com`;
  const key = url.includes(vhost) ? url.split(vhost)[1] : url.split(bucket)[1];
  let signedURL = await getSignedUrl({ key, bucket }, failIfKeyIsMissing);
  const signature = signedURL.split(/:443/)[1];
  return signature;
}

/**
 * Get bucket from url within the list of unit buckets
 * @param {string} url
 * @returns {bucket|false}
 * */
export const getValidBucketUrl = (url) => {
  const baseS3Url = url.split('.s3')[0];
  if (baseS3Url) {
    // eg ["https", "unit-resources-apc-nonprod"]
    return baseS3Url.split('://')[1];
  }
};

export async function getSignedUnitResource(url, failIfKeyIsMissing) {
  let resourceUrl = decodeURIComponent(url.replace(/\+/g, ' '));
  const urlBucket = getValidBucketUrl(resourceUrl);
  if (urlBucket) {
    const signature = await getSignedResource(
      resourceUrl,
      urlBucket,
      failIfKeyIsMissing,
    );
    const baseURL = `https://${urlBucket}.s3.amazonaws.com`;
    return `${baseURL}${signature}`;
  }
  throw new Error('Not a Unit Resource');
}

export const extractS3ResourceDomainFromUrl = (url) => {
  let newUrl = url;
  if (newUrl.startsWith(S3_RESOURCES_BUCKET)) {
    const urlParts = newUrl.split(`${S3_RESOURCES_BUCKET}/`);
    [, newUrl] = urlParts;
  }
  return newUrl;
};

export const isS3Resource = (url) =>
  (url.startsWith(S3_RESOURCES_BUCKET) &&
    S3_RESOURCES_BUCKET.includes('s3.amazonaws.com')) ||
  (!url.includes('http://') && !url.includes('https://'));

export const getResourceAddress = (url, subject) =>
  `${FYM_FRONTEND_URL}/${subject}/resources/${extractS3ResourceDomainFromUrl(
    url,
  )}`;

export const extractHtmlContent = (html, space) => {
  let span = document.createElement('span');
  span.innerHTML = html;
  if (space) {
    let children = span.querySelectorAll('*');
    for (let i = 0; i < children.length; i++) {
      if (children[i].textContent) {
        children[i].textContent += ' ';
      } else {
        children[i].innerText += ' ';
      }
    }
  }
  return [span.textContent || span.innerText].toString().replace(/ +/g, ' ');
};

export const parseHeaders = (headerString) =>
  headerString.split('\r\n').reduce((oldHeaders, header) => {
    const headers = { ...oldHeaders };
    if (header) {
      const split = header.split(':');
      // Headers come back with double quotes wrapping- the slice removes those.
      headers[split[0].trim().toLowerCase()] = split[1].trim().slice(1, -1);
    }
    return headers;
  }, {});

const SIGNING_ENDPOINT = `${FYM_BACKEND_URL}/media/api/signed_url`;

const wait = (millis = 1000) =>
  new Promise((resolve) => setTimeout(resolve, millis));

const signUrl = (data) =>
  axios({
    url: SIGNING_ENDPOINT,
    method: 'POST',
    data: JSON.stringify(typeof data === 'string' ? { url: data } : data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(getPayload);

const getPayload = (response) => {
  if (response.status !== 200 && response.status !== 304) {
    throw Error('Unexpected status code');
  }
  return response.data;
};

/* call a function that returns a promise and retry if it throws an exception */
export const retryPromise = (
  func,
  { timeout = 25 * 1000, interval = 1000 } = {},
) => {
  let closureTimeout = timeout;
  function requestLoop() {
    return func().catch(() => {
      if (closureTimeout <= 0) {
        throw Error('Timeout exceeded');
      }
      return wait(interval).then(() => {
        closureTimeout -= interval;
        return requestLoop();
      });
    });
  }
  return requestLoop();
};

export const waitForUrl = (url, options) =>
  retryPromise(
    () =>
      signUrl(url)
        .then(({ signedUrl }) => fetch(signedUrl, { method: 'HEAD' }))
        .then(getPayload),
    options,
  );

export const isPriorStudentsPage = (routes) => {
  const resultsRoute = routes.filter(
    (route) => route.name === 'resultsPages',
  )[0];
  const resultsIndex = routes.indexOf(resultsRoute);
  const resultsPageParentRoute = routes[resultsIndex - 1];
  if (!resultsPageParentRoute) {
    return false;
  }
  return resultsPageParentRoute.name === 'priorStudents';
};
export const getPriorStudentsSubdir = (routes) =>
  routes && isPriorStudentsPage(routes) ? '/prior_students' : '';
export const rebuildPathFromRoutes = (routes, params) => {
  /* eslint-disable no-restricted-syntax */
  // rebuild the path from new routes array
  let path = '';
  let route;
  for (route of routes) {
    if (typeof route.path === 'string') {
      path += `/${route.path}`;
    }
  }

  // first route is typically '/', which leades to //mypath/...
  // replace multiple slashs (/// => /)
  path = path.replace(/\/{2,}/g, '/');

  // fill out params from existing values
  let key;
  for (key in params) {
    if (Object.hasOwnProperty.call(params, key)) {
      path = path.replace(new RegExp(`:${key}`, 'g'), params[key]);
    }
  }
  return path;
  /* eslint-enable no-restricted-syntax */
};

export const getTestSelector = (id) => `[data-test-id="${id}"]`;

export const removeHtmlFromText = (string) =>
  !string ? '' : string.replace(/<\/?[^>]+(>|$)/g, '');

export const isValidJSONString = (string) => {
  try {
    return !!string && JSON.parse(string);
  } catch (e) {
    return false;
  }
};

export const parseNonEmptyJSONObject = (string) => {
  if (string !== '{}' && isValidJSONString(string)) {
    return JSON.parse(string);
  }
};

export const getSubGroupStudentsLabel = (subGroupStudents) => {
  const { length: subGroupStudentsLength } = subGroupStudents || [];

  if (subGroupStudentsLength === 0) {
    return '';
  }

  if (subGroupStudentsLength === 1) {
    const { firstName, lastName } = mapKeys(subGroupStudents[0], (_, key) =>
      camelCase(key),
    );
    return firstName && lastName
      ? ` (${firstName} ${lastName})`
      : ` (${subGroupStudents[0]})`;
  }

  return ` (${subGroupStudents.length} students)`;
};

export class AllAssignments {
  constructor(assessments = [], assessmentId = null) {
    const matchingAssessment = assessments.find(
      (assessment) => +assessment.id === +assessmentId,
    );

    if (matchingAssessment) {
      const { assignments } = matchingAssessment;
      this.assignments = assignments;
      this.assessment = matchingAssessment;
    } else {
      this.assignments = [];
    }
  }

  getAssignments = () => this.assignments;

  getIsFinalExam = () => this.assessment && this.assessment.is_final_exam;

  getOpenAssignments = () =>
    this.assignments.filter((assignee) => {
      let isAssignmentComplete = false;
      const { progress } = assignee;
      if (progress) {
        const finishedPercentage = getFinishedPercent(progress);
        isAssignmentComplete = finishedPercentage === 100;
      }
      return assignee.status !== 'closed' && !isAssignmentComplete;
    });

  getAssignmentsWithResults = () =>
    this.assignments.filter((assignee) => {
      const { progress } = assignee;
      if (progress) {
        return getScoredSubmissions(progress);
      }
      return false;
    });
}

export const KEY_CODES = {
  backspace: 8,
  tab: 9,
  return: 13,
  alt: 18,
  esc: 27,
  space: 32,
  leftArrow: 37,
  upArrow: 38,
  rightArrow: 39,
  downArrow: 40,
};

export const getRevokeAxiosPromise = (token, url = '') => {
  const revokePromise = axios.create({
    baseURL: url,
  });
  delete revokePromise.defaults.headers.common.Authorization;
  revokePromise.defaults.headers.common.authorization = `Bearer ${token}`;

  return revokePromise;
};

export const getLogoutPromises = () => {
  let promises = [];

  const ENDPOINTS = {
    ACCESS_TOKEN: '/auth/logout/',
    REFRESH_TOKEN: '/auth/logout2/',
  };

  const userAccessToken = window.localStorage.getItem(LC_CONSTANTS.AT);
  const revokeUserAccessToken =
    userAccessToken &&
    userAccessToken !== 'null' &&
    getRevokeAxiosPromise(
      userAccessToken,
      `${FYM_ACCOUNTS_URL}${ENDPOINTS.ACCESS_TOKEN}`,
    );
  revokeUserAccessToken && promises.push(revokeUserAccessToken.post());

  const userRefreshToken = window.localStorage.getItem(LC_CONSTANTS.RT);
  const revokeUserRefreshToken =
    userRefreshToken &&
    userRefreshToken !== 'null' &&
    getRevokeAxiosPromise(
      userRefreshToken,
      `${FYM_ACCOUNTS_URL}${ENDPOINTS.REFRESH_TOKEN}`,
    );
  revokeUserRefreshToken && promises.push(revokeUserRefreshToken.post());

  return promises;
};

export const getSelectedPlayer = (props) => {
  const { isQBCreateTab, blueBookUiFlag, isTopicQuestion } = props;

  if (isQBCreateTab && !blueBookUiFlag) {
    return 'Classic_Question_Preview_Player';
  } else if (isQBCreateTab && blueBookUiFlag && !isTopicQuestion) {
    return 'BB_Question_Preview_Player';
  } else if (isTopicQuestion && blueBookUiFlag) {
    return 'BB_Topic_Question_Preview_Player';
  } else if (blueBookUiFlag) {
    return 'BB_Assessment_Preview_Player';
  } else {
    return 'Classic_Assessment_Preview_Player';
  }
};
